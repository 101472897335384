body {
}

.sl_nav {
  background-color: #ffda3e !important;
  z-index: 101 !important;
}

.sl_navlogo {
  margin-top: 10px;
  margin-left: 10px;
}

.sl_pagespacer {
  height: 50px;
}

.sl_pagespacersmall {
  height: 20px;
}

.sl_cardcontent {
  padding-bottom: 0 !important;
}

.sl_card {
  box-shadow: 0 0 50px 10px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  padding: 5px;
}

.sl_nav_newtestmenulink {
  font-size: 20px;
  font-weight: bold;
  padding-right: 10px;
  color: #777777;
}

.sl_nav_urlbar {
  width: 100%;
  height : 40px;
  background-color: #eeeeee;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 8px;
  box-shadow: 0 0 50px 10px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  z-index: 100 !important;
}


.fa-rotate-30 {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sl_prouductimage {
  float: left;
}

.sl_productdetailsdiv {
  float: left;
}
